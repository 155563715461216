import React, { useState } from "react"
import "../styles/header.css"
import { GiHamburgerMenu } from "react-icons/gi"
import { Link } from "gatsby"
import logo from "../../static/images/aniversario_qualoom.png"
import {FaCaretRight} from "react-icons/fa"
import {BiChevronDownCircle} from "react-icons/bi"


const Header = () => {
  const [drop, setDrop] = useState(false)
  const onClick = () => {setDrop(!drop)}

  const [drop1, setDrop1] = useState(false)
  const Services = () => {if(!drop1){setService1(false);setService2(false);setService3(false);setService4(false);}setDrop1(!drop1)}

  const [service1, setService1] = useState(false)
  const Consult = () => {setService1(!service1)}

  const [service2, setService2] = useState(false)
  const Cloud = () => {setService2(!service2)}

  const [service3, setService3] = useState(false)
  const DevOps = () => {setService3(!service3)}

  const [service4, setService4] = useState(false)
  const Seguridad = () => {setService4(!service4)}

  const [drop2, setDrop2] = useState(false)
  const Company = () => {setDrop2(!drop2)}

  const [drop3, setDrop3] = useState(false)
  const Kit = () => {if(!drop3){setKit2(false);}setDrop3(!drop3)}

  const [kit2, setKit2] = useState(false)
  const Soluciones = () => {setKit2(!kit2)}

  const [drop4, setDrop4] = useState(false)
  const Program = () => {setDrop4(!drop4)}

  return (
    <div className="header">
      <nav>
        <div className="web-menu">
          <div className="toggle-menu">
            <Link to="/">
              <div className="header_logo anniversary">
                <img src={logo} alt="logo Qualoom" />
              </div>
            </Link>
            <div className="hamb-icon" id="menu">
              <GiHamburgerMenu size={25} onClick={onClick} onKeyDown aria-hidden/>
            </div>
          </div>
          <div className={drop? "hamb-menu" : "web-menu-items"}>
              <div className="dropdown">
                <span className="span4">Servicios<BiChevronDownCircle className={drop1? "mobile rotate" : "mobile"} size={24} onClick={Services}/></span>
                <div className={drop1? "dropdown-items show" : "dropdown-items"}>
                  <div className="dropdown2">
                    <Link className="item" to="/consultoria-tecnologica/"><span className="span5">Consultoría tecnológica</span><FaCaretRight className="desktop"/></Link>
                    <BiChevronDownCircle className={service1? "mobile rotate" : "mobile"} size={24} onClick={Consult}/>
                    <div className={service1? "dropdown2-items drop-1 show" : "dropdown2-items drop-1"}>
                      <Link className="item" to="/consultoria-tecnologica/transformacion-digital/"><span className="span6">Transformación digital</span></Link>
                    </div>
                  </div>
                  <div className="dropdown2">
                    <Link className="item" to="/cloud-infraestructura/"><span className="span5">Cloud & Infraestructura</span><FaCaretRight className="desktop"/></Link>
                    <BiChevronDownCircle className={service2? "mobile rotate" : "mobile"} size={24} onClick={Cloud}/>
                    <div className={service2? "dropdown2-items drop-2 show" : "dropdown2-items drop-2"}>
                      <Link className="item" to="/cloud-infraestructura/cloud-data/"><span className="span6">Cloud Data</span></Link>
                      <Link className="item" to="/cloud-infraestructura/migraciones/"><span className="span6">Migraciones</span></Link>
                      <Link className="item" to="/cloud-infraestructura/diseno-arquitecturas/"><span className="span6">Diseño de arquitecturas</span></Link>
                      <Link className="item" to="/cloud-infraestructura/administracion-gobierno/"><span className="span6">Administración y gobierno</span></Link>
                      <Link className="item" to="/cloud-infraestructura/rendimiento-escalabilidad/"><span className="span6">Rendimiento y escalabilidad</span></Link>
                      <Link className="item" to="/cloud-infraestructura/monitorizacion-alarmado/"><span className="span6">Monitorización y alarmado</span></Link>
                      <Link className="item" to="/cloud-infraestructura/optimizacion-costes/"><span className="span6">Optimización de costes</span></Link>
                      <Link className="item" to="/cloud-infraestructura/soporte-informatico/"><span className="span6">Soporte Informático</span></Link>
                      <Link className="item" to="/cloud-infraestructura/partner-advanced-aws/"><span className="span6">Partner Advanced AWS</span></Link>
                      <Link className="item" to="/cloud-infraestructura/partner-microsoft-azure/"><span className="span6">Partner Microsoft Azure</span></Link>
                      <Link className="item" to="/cloud-infraestructura/partner-google-cloud/"><span className="span6">Partner Google Cloud</span></Link>
                    </div>
                  </div>
                  <div className="dropdown2">
                    <Link className="item" to="/devops/"><span className="span5">Servicios DevOps</span><FaCaretRight className="desktop"/></Link>
                    <BiChevronDownCircle className={service3? "mobile rotate" : "mobile"} size={24} onClick={DevOps}/>
                    <div className={service3? "dropdown2-items drop-3 show" : "dropdown2-items drop-3"}>
                      <Link className="item" to="/devops/metodologia/"><span className="span6">Metodología DevOps</span></Link>
                      <Link className="item" to="/devops/integracion-continua/"><span className="span6">Integración continua</span></Link>
                      <Link className="item" to="/devops/agile/"><span className="span6">Agile DevOps</span></Link>
                    </div>
                  </div>
                  <div className="dropdown2">
                    <Link className="item" to="/seguridad-informatica/"><span className="span5">Seguridad informática</span><FaCaretRight className="desktop"/></Link>
                    <BiChevronDownCircle className={service4? "mobile rotate" : "mobile"} size={24} onClick={Seguridad}/>
                    <div className={service4? "dropdown2-items drop-4 show" : "dropdown2-items drop-4"}>
                      <Link to="/seguridad-informatica/ciberseguridad/"><span className="span6 item">Ciberseguridad</span></Link>
                      <Link to="/seguridad-informatica/auditoria/"><span className="span6 item">Auditorías</span></Link>
                      <Link to="/seguridad-informatica/devsecops/"><span className="span6 item">DevSecOps</span></Link>
                      <Link to="/seguridad-informatica/qualys/"><span className="span6 item">Qualys VMDR</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <span className="span4">Nuestra empresa<BiChevronDownCircle className={drop2? "mobile rotate" : "mobile"} size={24} onClick={Company}/></span>
                <div className={drop2? "dropdown-items show" : "dropdown-items"}>
                  <Link className="item" to="/compania/"><span className="span5">Compañía</span></Link>
                  <Link className="item" to="/normativa-iso-27001/"><span className="span5">Normativa y cumplimiento</span></Link>
                  <Link className="item" to="/normativa-conducta/"><span className="span5">Código ético</span></Link>
                </div>
              </div>
              <div className="dropdown">
                <span className="span4">Kit Digital<BiChevronDownCircle className={drop3? "mobile rotate" : "mobile"} size={24} onClick={Kit}/></span>
                <div className={drop2? "dropdown-items show" : "dropdown-items"}>
                  <Link className="item" to="/kit-digital/"><span className="span5">¿Qué es el Kit Digital?</span></Link>
                  <Link className="item" to="/solicitar-bono-kit-digital/"><span className="span5">Solicitar el Bono Digital</span></Link>
                  <div className="dropdown2">
                    <Link className="item" to="/soluciones-kit-digital-qualoom/"><span className="span5">Soluciones del Kit Digital</span><FaCaretRight className="desktop"/></Link>
                    <BiChevronDownCircle className={kit2? "mobile rotate" : "mobile"} size={24} onClick={Soluciones}/>
                    <div className={kit2? "dropdown2-items drop-3 show" : "dropdown2-items drop-3"}>
                      <Link className="item" to="/kit-digital/soluciones-web/"><span className="span6">Soluciones Web</span></Link>
                      <Link className="item" to="/kit-digital/comunicaciones-seguras/"><span className="span6">Comunicaciones Seguras</span></Link>
                      <Link className="item" to="/kit-digital/comercio-electronico/"><span className="span6">Comercio electrónico</span></Link>
                      <Link className="item" to="/kit-digital/redes-sociales/"><span className="span6">Gestión de Redes Sociales</span></Link>
                      <Link className="item" to="/kit-digital/ciberseguridad/"><span className="span6">Ciberseguridad</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <span className="span4">Partners<BiChevronDownCircle className={drop4? "mobile rotate" : "mobile"} size={24} onClick={Program}/></span>
                <div className={drop3? "dropdown-items show" : "dropdown-items"}>
                  <Link className="item" to="/partner-program/"><span className="span5">Partner Program</span></Link>
                  <Link className="item" to="/partner-program/inscripcion/"><span className="span5">Conviértete en Partner</span></Link>
                  <Link className="item" to="/partner-program/miembros/"><span className="span5">¿Buscas un Partner?</span></Link>
                  <Link className="item" to="/partner-program/recursos/"><span className="span5">Recursos del Partner</span></Link>
                </div>
              </div>
              <Link className="item" to="/casos-de-exito/"><span className="span4">Casos de éxito</span></Link>
              <Link className="item" to="/blog/"><span className="span4">Blog</span></Link>
              <Link className="item" to="/actualidad/"><span className="span4">Actualidad</span></Link>
              <Link className="item" to="/contacto/"><span className="span4">Contacto</span></Link>
            </div>
        </div>
      </nav>
    </div>
  )
}

export default Header

