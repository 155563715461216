import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/footer.css"
import { TinyButton as ScrollUpButton } from "react-scroll-up-button"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"
import twitter from "../../static/images/x-twitter.svg"
import logo from "../../static/images/logo-menu.png"
import Applus from "../../static/images/certificados/applus_iso.svg"
import CookieConsent from "react-cookie-consent"


const Footer = () => {
  const data = useStaticQuery(graphql`
    query Images_index2 {
      image: file(
        relativeDirectory: { eq: "footer" }
        name: { eq: "puntos-footer" }
      ) {
        id
        childImageSharp {
          fluid{
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function Twitter() {window.open ("https://twitter.com/qualoom");}
  function Facebook() {window.open ("https://www.facebook.com/qualoom.expertise.technology")}
  function LinkedIn() {window.open ("https://www.linkedin.com/company/qualoom/mycompany/")}

  return (
    <div className="footer">
      <BackgroundImage fluid={data.image.childImageSharp.fluid}>
        <div className="backgroud-color">  
          <div className="footer_primary">
            <div className="logo">
              <Link to="/"><img src={logo} alt="logo Qualoom" /></Link>
            </div>
            <div className="footer-list">
              <div className="col-1-5">
                <Link to="/compania/"><span className="span5">Compañía</span></Link>
                <Link to="/compania/#about-us"><span>Sobre nosotros</span></Link>
                <Link to="/compania/#our-team"><span>Nuestro equipo</span></Link>
                <Link to="/compania/#certifications"><span>Seguridad y <br/>Certificaciones</span></Link>
              </div>
              <div className="col-1-5">
                <span className="span5">Servicios</span>
                <Link to="/consultoria-tecnologica/"><span>Consultoría tecnológica</span></Link>
                <Link to="/cloud-infraestructura/"><span>Cloud & Infraestructura</span></Link>
                <Link to="/devops/"><span>Servicios DevOps</span></Link>
                <Link to="/seguridad-informatica/"><span>Seguridad informática</span></Link>
              </div>
              <div className="col-1-5">
                <span className="span5">Casos de éxito</span>
                <Link to="/caso-de-exito-interflora/"><span>Interflora </span></Link>
                <Link to="/casos-de-exito/"><span>La Liga Profesional</span></Link>
                <Link to="/casos-de-exito/"><span>Telefónica</span></Link>
              </div>
              <div className="col-1-5">
                <span className="span5">Recursos</span>
                <Link to="/blog/"><span>Blog</span></Link>
                <Link to="/kit-digital/"><span>Kit Digital</span></Link>
                <Link to="/empleo/"><span>Empleo</span></Link>                
                <Link to="/actualidad/"><span>Actualidad</span></Link>
              </div>
              <div className="col-1-5 certificates">
                <span className="span5">Certificaciones</span>
                <div className="certificates">
                <a href="/documents/certificates/Qualoom Expertise Technology - SGSI Certificado ISO270012022 Seguridad de la informacion.pdf" target="_blank"><img src={Applus} alt="Certificado ISO27001:2022"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer_secondary">
            <div className="policy-copyright">
              <div className="policy">
                <Link to="/aviso-legal/"><span className="span4">Aviso Legal</span></Link>
                <Link to="/politica-de-privacidad/"><span className="span4">Política de Privacidad</span></Link>
                <Link to="/politica-de-cookies/"><span className="span4">Política de Cookies</span></Link>
                <Link to="/politica-de-seguridad/"><span className="span4">Política de Seguridad</span></Link>
              </div>
              <div className="copyright"><p>@{new Date().getFullYear()} Qualoom Expertise Technology. Todos los derechos reservados.</p></div>
            </div>
            <div className="social">
                <span className="icon" onClick={Twitter} onKeyDown aria-hidden><img src={twitter} alt="Twitter"/></span>
                <span className="icon" onClick={Facebook} onKeyDown aria-hidden><FaFacebookF/></span>
                <span className="icon" onClick={LinkedIn} onKeyDown aria-hidden><FaLinkedinIn/></span>
            </div>
            <div><ScrollUpButton ContainerClassName="ScrollUp" ToggledStyle={{ right: 20, bottom: 40 }} /></div>
          </div>
        </div>
      </BackgroundImage>
      <CookieConsent buttonText="ACEPTAR" location="bottom" cookieName="myAwesomeCookieName3" expires={999}>Este sitio web utiliza cookies para que usted tenga la mejor experiencia de usuario. Si continúa navegando está dando su consentimiento para la aceptación de las mencionadas cookies y la aceptación de nuestra &nbsp;<Link className="cookies-link" to="/politica-de-cookies/">Política de cookies</Link>
      </CookieConsent>
    </div>
  )
}

export default Footer
